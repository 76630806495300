@import "variables.scss";

$selectedNumbering: url("../img/atoms/pagination/numbering-selected@3x.png");

$backNumbering: url("../img/atoms/pagination/button-numbering-back@3x.png");
$nextNumbering: url("../img/atoms/pagination/button-numbering-next@3x.png");
$hoverBackNumbering: url("../img/atoms/pagination/button-numbering-back-hover@3x.png");
$hoverNextNumbering: url("../img/atoms/pagination/button-numbering-next-hover@3x.png");

$checkbox-on: url("../img/atoms/checkbox/button-chek-on@3x.png");
$history-back-icon: url("../img/pages/customer/board/notice/icon-back@3x.png");

$dateCalendar: url("../img/atoms/calendar/icon-calendar@3x.png");

$radioOn: url("../img/atoms/radio/radio-on@2x.png");
$radioOff: url("../img/atoms/radio/radio-off@2x.png");
$radioOff: url("../img/atoms/radio/radio-off@3x.png");
$radioSelectOff: url("../img/atoms/radio/radio-select.png");

$pWIconOn: url("../img/main/password/button-password-on@2x.png");
$pWIconOn: url("../img/main/password/button-password-on@3x.png");
$pWIconOff: url("../img/main/password/button-password-off@2x.png");
$pWIconOff: url("../img/main/password/button-password-off@3x.png");

// paging
.MuiPaginationItem-page.Mui-selected {
    background-color: transparent !important;
    color : #2683F2;
}

.datePicker2 .dateClass input.datePicker{
    box-sizing: border-box; 
    width: 130px;
    height: 30px;
    margin-bottom: 8px;
}

.contensSection {
    overflow: scroll !important; 
}

.datePicker3{
    // height: 30px;
    align-content: center;
    .datePicker4 {
        padding: 0px 12px;
        border: 1px solid #dfdfdf;
        border-radius: 5px;
        outline: none;
        height: 28px;
        background-image: $dateCalendar; // 달력안 이미지
        background-repeat: no-repeat;
        background-position: 95% 50%;
        background-size: 20px 20px;
        cursor: pointer;
        width: 130px;
        display: flex;
        font-family: $basic-font;
    }
}

.customdate-picker {

    align-content: center;

    .datepicker-contents {
        padding: 0px 12px;
        border: 1px solid #dfdfdf;
        border-radius: 5px;
        outline: none;
        height: 28px;
        background-image: $dateCalendar; // 달력안 이미지
        background-repeat: no-repeat;
        background-position: 95% 50%;
        background-size: 20px 20px;
        cursor: pointer;
        width: 130px;
        display: flex;
        font-family: $basic-font;
    }
   
    // 선택된 값 폰트컬러
    .react-datepicker__day--in-range {
        color: white !important;
    }    

    // 선택된 월 css
    .default-date-picker-container {
        .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__month-text--keyboard-selected {
            background-color : #2683F2 !important;
            color : #FFFFFF;
            border-radius: 120px;
        }
    }

    // RangeDatePicker component 날짜 선택이 안되어있게 보이도록 css 적용
    .default-value-none-selected {
        .react-datepicker__day--in-range {
            background-color: #FFFFFF !important;
            color: #000 !important;
            display: inline-block !important;
            width: 1.7rem !important;
            line-height: 1.7rem !important;
            text-align: center !important;
            margin: 0.166rem !important;
        }
    }

    .unselected-month-container{
        // 다른연도일때, 선택안된것처럼 보이게
        .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__month-text--keyboard-selected {
            background-color: #FFFFFF !important;
            color : #1E2939;
            border-radius: 120px;
        }

        .react-datepicker__month--selecting  {
            color: white !important;
        }
    }

    // 선택불가 월 폰트컬러
    .react-datepicker__month--disabled, .react-datepicker__quarter--disabled {
        color: #999999 !important;
    }
}

.filterDiv{
    float:right;
    margin-bottom:8px;
}

/* 로딩 */
#bgLoader {
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.75);
    -webkit-tap-highlight-color: transparent;

    & svg {
        animation: animation-rotate-img 2s linear infinite;
        transform-origin: 50% 50%;
        font-size: 100px;
    }
}

// selectbox list hover color
.MuiListItem-button:hover {
    background-color: #EFF8FF !important;
}

// selectbox 선택항목 호버전, 컬러 제거
.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover{
    background-color: #EFF8FF !important;    
}
// selectbox 선택항목 호버시, 컬러 적용
.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover::before{
    background-color : transparent !important;
}

// 테이블 내부의 체크박스가 체크 되었을 때, 체크된 tr의 자식 css 변경
.MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover {
    background-color:  #ECF6FF !important;
    >td {
        >div {
            color: #2683F2 !important;
            >button {
                color: #2683F2 !important;    
            }
        }
    }
}

// 정산관리 승차권 조회 취소 상세 - 편도 취소수수료 발생 티켓 row 붉은색 표시
.OnewayTable.MuiTableRow-hover.Mui-selected {
    background-color: #FFF7F7 !important;
    >td {
        >div {
            color : #FF4844 !important;
        }
    }
}

/* 버튼 */
body .rev-button-root {
    text-transform: unset;
    background-color: transparent;
    font-weight: bold;
    color: rgba(49, 39, 39, 0.87);
    font-size: 13px;
    min-width: 54px;
    box-sizing: border-box;
    height: 36px;
    border-radius: 4px;
    cursor: pointer;
    outline: 0;
    padding: 10px 18px;

    &.MuiButton-contained:hover {
        box-shadow: unset;
    }

    &.outlined {
        border: 10.5px solid $primary;
        background-color: #fff;
        .button-label {
            color: $primary;
        }

        &:active {
            color: $primary;
        }

        &.primary {
            border: 1px solid $primary-border;

            &:hover {
                background-color: $primary-horver-background-color;
            }

            &:active {
                background-color: $primary-active-background-color;
            }
        }

        &.secondary {
            border: 1px solid $primary-border;

            &:hover {
                background-color: $primary-horver-background-color;
            }

            &:active {
                & span.button-label {
                    color: $primary;
                }

                background-color: $primary-active-background-color;
            }
        }
    }

    &.back {
        .button-label::before {
            display: inline-block;
            vertical-align: middle;
            content: "";
            width: 14px;
            height: 15px;
            background: transparent;
            background-image: $history-back-icon;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
        }
    }

    &.contained {
        box-shadow: unset;

        &.primary {
            background-color: $primary;

            &:hover {
                background-color: $primary-contained-horver-background-color;
                box-shadow: 0 4px 16px 0 rgba(255, 205, 0, 0.28);
            }

            &:active {
                background-color: $main-contained-horver-background-color;
            }
        }

        &.secondary {
            background-color: $secondary;
        }

        @mixin disabled-button {
            background-color: #eeeeee;

            & .button-label {
                color: $font-color--default;
            }
        }

        &.disabled {
            @include disabled-button;

            &:hover {
                @include disabled-button;
            }
        }
    }

    &.main {
        background-color: $main;

        &:hover {
            background-color: $main-contained-horver-background-color;
        }

        &:active {
            background-color: $main-contained-horver-background-color;
        }
    }


    .button-label {
        font-size: 13px;
        min-width: 36px;
        height: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: -0.11px;
        text-align: center;
        color: #FFFFFF;
        font-family: $basic-font;

        &.primary {
            color: $primary;
        }

        &.secondary {
            color: $secondary;
        }

        &.black {
            color: $black;
        }

        &.white {
            color: $white;
        }
    }
}

/* 테이블 */
table.rev-table-root {
    width: 100%;
    table-layout: fixed;
    transition: all 0.25s ease;
    border-collapse: separate;
    border-spacing: 0 1px;
    position: relative;

    .thead {
        border-bottom: solid 1px #ececec;
        position: relative;

        .row {
            position: relative;

            th {
                height: 44px;
                font-weight: bold;
                border: 0px;

                &:first-child {
                    text-align: center;
                    padding: 0px 11px 0px 0px;
                }

                &.MuiTableCell-paddingCheckbox {
                    & span.MuiCheckbox-root {
                        float: left;
                        margin-left: 7px;
                    }
                }

                &.MuiTableCell-stickyHeader {
                    z-index: 1;
                    background-color: #f7f8fa;
                    border-top: solid 1px #ececec;
                    border-bottom: solid 1px #ececec;
                }
            }

            .cell {
                &:not(.visible) {
                    display: none;
                }

                font-size: inherit;
                padding: 0px;
                letter-spacing: -0.13px;

                &.center {
                    text-align: center;
                }

                &.left {
                    text-align: left;
                }

                &.right {
                    text-align: right;
                }
            }
        }
    }

    .tbody {
        position: relative;

        .row {
            position: relative;
            height: 44px;
            cursor: pointer;
            display: table-row;

            &.Mui-selected {
                border-radius: 4px;
                background-color: #fff;

                td {
                    color: #b28b00 !important;
                    background-clip: content-box;
                    background-color: #fff8db;

                    &:first-child {
                        border-top-left-radius: 8px;
                        border-bottom-left-radius: 8px;
                    }

                    &:last-child {
                        border-top-right-radius: 8px;
                        border-bottom-right-radius: 8px;
                    }
                }
            }

            .MuiTable-stickyHeader {
                border-collapse: unset;
            }

            .cell {
                font-size: inherit;
                padding: 0px;
                border: 0px;
                letter-spacing: -0.13px;

                &.MuiTableCell-alignLeft {
                    &:first-child {
                        padding: 0px 0px 0px 2%;
                    }
                }

                &:not(.visible) {
                    display: none;
                }

                & span.MuiCheckbox-root {
                    float: left;
                    margin-left: 7px;
                }

                div {
                    display: table-cell;
                    vertical-align: middle;

                    &.MuiIconButton-label {
                        height: 14px;
                    }

                    &:not(.MuiIconButton-label) {
                        height: 43px;
                        width: 100vw;
                        font-family: $basic-font;
                    }
                }
            }

            &:hover {
                background-color: transparent;

                td:first-child:before,
                .cell:first-child:before {
                    box-sizing: border-box;
                    content: "";
                    position: absolute;
                    left: 2px;
                    right: 2px;
                    display: block;
                    height: 44px;
                    width: inherit;
                    background-color: transparent;
                    border-radius: 8px;
                    -webkit-box-shadow: 0px 1px 5px 0 rgba(0, 0, 0, 0.12);
                    -moz-box-shadow: 0px 1px 5px 0 rgba(0, 0, 0, 0.12);
                    box-shadow: 0px 1px 5px 0 rgba(0, 0, 0, 0.12);
                }
            }
        }
    }
}

/* 페이징 */
.rev-pagination-root {
    list-style: none;
    display: inline-flex;
    margin: auto;
    text-align: center;

    a {
        font-size: 13px;
        outline: 0;
        display: block;
        height: 100%;

        &:visited,
        &:link {
            text-decoration: none;
            color: #000;
        }
    }

    li {
        display: inline-block;

        &.pNumber {
            height: 26px;
            border-radius: 30px;
            background-color: #f7f7f7;
            font-size: 10px;

            ul {
                padding-left: 0px;

                li {
                    height: 26px;
                    width: 26px;
                    line-height: 2;
                    margin-right: 15px;

                    &:last-child {
                        margin-right: 0px;
                    }

                    &:hover {
                        a {
                            font-weight: bold;
                        }
                    }

                    &.pActive {
                        background: transparent;
                        background-image: $selectedNumbering;
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-size: cover;
                    }
                }
            }
        }

        &.pPrevious {
            margin-right: 12px;
            width: 26px;
            height: 26px;
            background: transparent;
            background-image: $backNumbering;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;

            &:hover {
                background-image: $hoverBackNumbering;
            }
        }

        &.pNext {
            margin-left: 12px;
            width: 26px;
            height: 26px;
            background: transparent;
            background-image: $nextNumbering;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;

            &.disabled {
                display: none;
            }

            &:hover {
                background-image: $hoverNextNumbering;
            }
        }
    }
}

/* 모달 */
.rev-modal-root {
    display: none;
    font-family: $basic-font;

    &.dialog {
        .rev-modal-background-root {
            z-index: 3;
        }

        .rev-modal-contents {
            z-index: 4;
        }
    }

    & .rev-modal-background-root {
        z-index: 2;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        position: fixed;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.75);
        -webkit-tap-highlight-color: transparent;
    }

    & .rev-LoginModal-background-root {
        z-index: 2;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        position: fixed;
        align-items: center;
        justify-content: center;
        // background-color: rgba(0, 0, 0, 0.75);
        -webkit-tap-highlight-color: transparent;
    }

    &.open {
        overflow: hidden;
        display: block;

        .rev-modal-contents {
            overflow-y: auto;
            z-index: 3;
            border-radius: 4px;
            animation-name: animation-fade-in;
            animation-duration: 0.3s;
            width: inherit;
            height: inherit;
            position: fixed;
            height: 164px;
            box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
                0px 5px 8px 0px rgba(0, 0, 0, 0.14),
                0px 1px 14px 0px rgba(0, 0, 0, 0.12);
            background-color: #fff;
            top: 50%;
            left: 50%;
            // padding: 0px 20px 0px 30px;
            
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

            .childContents {
                padding: 30px 30px 30px 30px;
                height: 100%;
            }

            img.closeIcon {
                visibility: hidden;
                float: right;
                margin-top: 12px;
                margin-right: 12px;
                cursor: pointer;

                &.close {
                    visibility: visible;
                }
            }
        }
    }
}

/* 리사이징 모달 */
.rev-resize-modal-root {
    display: none;
    font-family: $basic-font;

    &.dialog {
        .rev-modal-background-root {
            z-index: 3;
        }

        .rev-modal-contents {
            z-index: 4;
        }
    }

    & .rev-modal-background-root {
        z-index: 2;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        position: fixed;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.75);
        -webkit-tap-highlight-color: transparent;
    }

    &.open {
        overflow: hidden;
        display: block;

        .rev-modal-contents {
            overflow-y: hidden;
            z-index: 3;
            border-radius: 4px;
            animation-name: animation-fade-in;
            animation-duration: 0.3s;
            width: inherit;
            height: inherit;
            position: fixed;
            height: 164px;
            box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
                0px 5px 8px 0px rgba(0, 0, 0, 0.14),
                0px 1px 14px 0px rgba(0, 0, 0, 0.12);
            background-color: #f9f9f9;
            top: 50%;
            left: 50%;
            // padding: 0px 20px 0px 30px;
            padding: 30px 30px 30px 30px;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

            img.closeIcon {
                visibility: hidden;
                float: right;
                // margin-top: 20px;
                cursor: pointer;

                &.close {
                    visibility: visible;
                }
            }

            .handle {
                position: absolute;
                width: 20px;
                height: 20px;
                bottom: 0;
                right: 0;
                background-repeat: no-repeat;
                background-origin: content-box;
                box-sizing: border-box;
                cursor: se-resize;
            }
        }
    }
}

/* 다이얼로그 */
.rev-dialog-root {
    display: none;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;

    &.on {
        display: block;

        & h4 {
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            margin-top: 0px;
            margin-bottom: 14px;
        }

        .rev-modal-contents {
            overflow-y: hidden;
            padding: 30px 41px 20px 41px;

            img.closeIcon {
                display: none;
            }
        }

        & ul {
            list-style: none;
            padding-left: 0px;
            margin-bottom: 0px;

            & li {
                &.con {
                    text-align: center;
                    font-size: 14px;
                    margin-bottom: 26px;
                }

                &.cButton {
                    // margin-top: 26px;
                    text-align: center;

                    & button {
                        min-width: 112px;
                        height: 36px;
                        margin-right: 6px;

                        &:last-child {
                            margin-right: 0px;
                        }
                    }
                }
            }
        }
    }
}

/* 원형 프로그레스바 */
.rev-circular-progress {
    &:not(.isModal) {
        .circular {
            width: 70px !important;
            height: 70px !important;
            transform: rotate(-90deg) !important;
        }

        .percent {
            position: absolute;
            margin-left: 20px;
        }
    }

    &.isModal {
        display: block;
        z-index: 5;
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-tap-highlight-color: transparent;

        .progress {
            position: absolute;
            top: 50%;
            left: 50%;
            padding: 5px 20px;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

            .circular {
                width: 70px !important;
                height: 70px !important;
                transform: rotate(-90deg) !important;
            }
        }

        .percent {
            position: absolute;
            top: calc(50% - 2px);
            left: calc(50% + 2px);
            padding: 5px 20px;
            transform: translate(-50%, -50%);
            font-weight: bold;
            font-size: 14px;
            color: #fff;
        }
    }
}

/* 라인형 프로그레스바 */
.rev-linear-progress {
    .progress {
        .linear {
            color: $primary;
        }
    }
}

/* 드롭다운박스 */
.operWork {
    margin-left: 5px;
}

.rev-selectbox-root {
    width: 132px;
    position: relative;
    display: inline-block;
    border-radius: 4px;

    &.disabled {
        display: block;
        width: 100%;
        background-color: #f7f7f7;
        border: 0px;
        padding: 9.5px 12px;

        &.valid {
            border: solid 1px #ff0000;

            & img {
                &.essential {
                    display: inline;
                }
            }
        }
    }

    p {
        display: block;
        width: 100%;
        margin: 0px;
        padding: 9.5px;
        border-radius: 4px;
        border: solid 1px #dfdfdf;
        background-color: #ffffff;
        cursor: pointer;
        text-align: left;

        & span {
            display: inline !important;
            font-size: 13px;
        }

        & img {
            float: right;
        }
    }

    & .bg {
        display: none;
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 0;

        &.active {
            display: block;
            z-index: 1;
        }
    }

    & ul {
        list-style: none;
        display: none;
        padding-left: 0px;

        &.active {
            max-height: 400px;
            overflow-y: auto;
            margin: 0px;
            display: block;
            position: absolute;
            z-index: 2;
            width: 100%;
            border-radius: 4px;
            box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.18);
            background-color: #ffffff;
            padding: 4.8px;
            margin-top: -40px;

            &::-webkit-scrollbar {
                display: none;
            }

            li {
                display: inline-flex;
                width: 100%;
                cursor: pointer;
                background-color: #ffffff;
                margin-bottom: 5px;

                p {
                    display: block;
                    height: 30px;
                    border-radius: 4px;
                    background-color: #ffffff;
                    padding: 0px;
                    border: 0px;
                    transition: all 0.25s ease;
                    padding-top: 8px;
                }

                &.placeholder {
                    &:hover {
                        p {
                            background-color: transparent;
                        }
                    }

                    p {
                        color: #888888;
                        padding-left: 14px;
                        cursor: initial;
                    }
                }

                & img {
                    float: left;
                    visibility: hidden;
                    margin-right: 1px;

                    &.active {
                        visibility: visible;
                    }
                }

                &:hover {
                    p {
                        background-color: rgba(255, 205, 0, 0.14);
                    }
                }
            }
        }
    }
}

/* 드롭다운박스 - 기본용 */
.rev-selectboxDefault-root {
    width: 100%;
    display: block;

    & p:first-child {
        display: flex;

        & span {
            width: 100%;
        }
    }

    & ul.active {
        margin-top: -37px;
        max-height: 200px;
        overflow-y: scroll;
    }
}

/* 드롭다운박스 - 운영자 역할용 */
.rev-selectboxRole-root {
    width: 100%;
    display: block;

    & .bg {
        display: none;
        width: 100vw;
        height: 100vh;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 0;

        &.active {
            display: block;
        }
    }

    & p:first-child {
        display: flex;

        & span {
            width: 100%;
        }
    }

    & ul {
        height: 200px !important;
    }

    & ul.active {
        margin-top: -37px;
        max-height: 185px;
        overflow-y: scroll;
    }
}

/* 드롭다운박스 - 정렬 */
.rev-selectbox-orderby-root {
    ul {
        li:nth-child(odd):not(:first-child) {
            &::before {
                content: "";
                position: absolute;
                left: 14px;
                width: 80%;
                border-bottom: solid 1px #ececec;
            }

            & p {
                margin-top: 5px;
            }
        }
    }
}

/* input */
.rev-input-root {
    display: inline-flex;
    font-family: $basic-font;
    font-size: 13px;
    padding: 9px 12px;
    border-radius: 4px;
    border: solid 1px #dfdfdf;
    background-color: #ffffff;
    outline-color: #fff;
    float: left;
    border-radius: 4px;
    margin-left: 0px;

    img {
        cursor: pointer;
        vertical-align: middle;

        &.cancel {
            visibility: hidden;
            float: left;
            display: block;
            width: auto;
            margin-top: 1px;
        }

        &.searchActive {
            visibility: hidden;
            float: right;
            display: block;
        }

        &.search {
            display: inline;
            float: left;
            display: block;
            margin-right: 4px;
            width: auto;
            height: 100%;
        }
    }

    &.focus {
        border: solid 1.5px $primary;

        img {
            &.search {
                display: none;
            }

            &.searchActive {
                visibility: visible;
                width: auto;
                height: 100%;
                margin-top: -2px;
                width: auto;
                height: 18px;
                margin-left: 12px;
            }
        }

        &.x {
            img {
                &.cancel {
                    visibility: visible;
                }
            }
        }
    }

    input {
        font-family: $basic-font;
        display: block;
        width: 100%; //calc(100% - 21px);
        text-align: left;
        float: left;
        padding: 0px;
        border: 0px;
        caret-color: #fff;
        background: none;
        box-sizing: content-box;
        font-size: 13px;
        color: #000;
        height: 100%;

        &:focus-within {
            caret-color: $primary;
            outline: 0;
        }

        &::placeholder {
            color: #999;
        }

        &:disabled {
            color: #000;
            -webkit-text-fill-color: #000;
            opacity: 1;
            font-family: $basic-font;
        }
    }
}

/* 테이블내 검색옵션 input */
.rev-tableSearchInput-root {
    width: 390px;

    & input {
        font-family: $basic-font;

        img {
            &.cancel {
                margin-right: 12px;
            }
        }
    }
}

/* 기본 입력란에 사용되는 input */
.rev-inputDefault-root {
    transition: all 0.25s ease;
    display: flex;
    min-width: fit-content;
    width: inherit;
    padding: 8px 12px;

    &.disabled {
        background-color: #f7f7f7;
        border: 0px;
        padding: 9.5px 12px;
    }

    img {
        &.essential {
            display: none;
            width: auto;
            margin-top: 1px;
        }

        &.check {
            display: none;
            margin-top: 1px;
        }
    }

    &.exist:not(.disabled):not(.focus) {
        img {
            &.check {
                display: inline;
            }
        }
    }

    &.valid {
        border: solid 1px #ff0000;
        background-color : #FFF7F7;
        & img {
            &.essential {
                display: inline;
            }
        }
    }
}

/* 패스워드 입력 시 사용되는 input */
.rev-pwInput-root {
    display: block;
    font-size: 13px;
    padding: 9.5px;
    border-radius: 4px;
    border: solid 1px #dfdfdf;
    background-color: #ffffff;
    outline-color: #fff;
    float: left;
    margin-left: 0px;

    button {
        cursor: pointer;
        vertical-align: middle;

        &.searchPw {
            display: inline;
            float: left;
            margin-right: 4px;
            background-image: $pWIconOff; // 비밀번호 아이콘 off 변경
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: 20px 20px;
            background-color: #fff;
            border: 0px;
            padding: 5px 10px;
        }
    }

    &.focus {
        border: solid 1.5px $primary;

        img {
            &.searchActive {
                visibility: visible;
            }
        }
    }

    // 비밀번호 보기 버튼 활성화 되었을때
    &.active {
        border: solid 1.5px $primary;

        button {
            &.searchPw {
                cursor: pointer;
                background-image: $pWIconOn; // 비밀번호 아이콘  on 변경
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: 20px 20px;
                background-color: #fff;
                border: 0px;
                padding: 5px 10px;
            }
        }
    }

    input {
        display: block;
        width: calc(100% - 21px);
        height: 14px;
        text-align: left;
        float: left;
        padding: 0px;
        border: 0px;
        caret-color: #fff;
        background: none;
        box-sizing: content-box;

        &:focus-within {
            caret-color: $primary;
            outline: 0;
        }
    }
}

/* 운영자관리 비밀번호 입력란에 사용되는 input */
.rev-operPwInput-root {
    transition: all 0.25s ease;
    display: flex;
    min-width: fit-content;
    width: inherit;
    //padding: 8px 12px;

    &.disabled {
        background-color: #f7f7f7;
        border: 0px !important;
        padding: 9.5px 12px;

        button {
            &.searchPw {
                display: none;
            }
        }

        img {
            &.search {
                display: none;
            }

            &.searchPw {
                display: none;
            }

            &.searchActive {
                display: none;
            }
        }
    }

    img {
        &.essential {
            display: none;
        }

        &.check {
            display: none;
        }
    }

    &.exist:not(.disabled):not(.focus) {
        img {
            &.check {
                display: inline;
            }
        }
    }

    &.valid {
        border: solid 0.5px #ff0000;

        & img {
            &.essential {
                display: inline;
            }
        }
    }
}

.essentialComment {
    font-size: 10px;
    color: #ff0000;
    padding-left: 13px;
    display: flex;
    width: 100%;
    height: auto;
    margin: 0px;
    padding-top: 4px;
}

/* 기본 입력란에 사용되는 textarea */
.rev-textareaCommon-root {
    display: block;
    min-width: 100%;
    width: inherit;
    height: 100px;

    img {
        &.essential {
            display: none;
        }
    }

    textarea::placeholder {
        color: #999999;
        //font-size: 13px;
        font-family: $basic-font;
    }

    &:not(.disabled) {
        textarea {
            border: 0px;
            background-color: #ffffff;
            outline: 0px;
            padding: 9px;
            height: 100%;
            font-size: 13px;
            border: solid 1.5px #dfdfdf;
            border-radius: 4px;
            color: #000;
            -webkit-appearance: none;
        }

        &.focus {
            textarea {
                border: 0px;
                outline-color: #fff;
                border: solid 1.5px $primary;
            }
        }

        &.valid {
            textarea {
                border: solid 1px #ff0000;
            }

            .essentialComment {
                display: block;
                margin: 0px;
                padding-top: 4px;
                padding-left: 10px;
            }
        }
    }

    &.disabled {
        textarea {
            border: 0px;
            background-color: #f7f7f7;
            padding: 9.5px 12px;
            height: 100%;
            font-size: 13px;
            border-radius: 4px;
            color: #000;

            &::placeholder {
                color: #999;
            }

            &:disabled {
                color: #000;
                -webkit-text-fill-color: #000;
                opacity: 1;
                font-family: $basic-font;
            }
        }

        .essentialComment {
            display: none;
        }
    }
}

/* 그룹옵션 */
.rev-optionGroup-root {
    width: fit-content;
    display: inline-flex;

    &.disabled {
        display: block;
        width: initial;
        background-color: #f7f7f7;
        border: 0px;
        padding: 9.5px 12px;
        border-radius: 4px;
    }

    ul {
        list-style: none;
        display: none;
        padding: 0px;
        display: flex;
        height: fit-content !important;
        border-radius: 5px;
        border: solid 1px #dfdfdf;
        background-color: #ffffff;
        margin: 0px;

        li {
            line-height: 1;
            cursor: pointer;
            text-align: center;
            min-width: 48px;
            float: left;
            padding: 10px 12px;
            border-right: solid 1px #dfdfdf;
            // 운영자관리 추가
            width: max-content;

            &:first-child {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }

            &:last-child {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-right: 0px;
            }

            &:hover {
                background-color: $primary-horver-background-color;
            }

            &.on {
                background-color: $primary;
            }
        }
    }
}

/* 토스트 */
.rev-toast-container-root {
    right: 0;
    top: 122px;
    z-index: 9999;
    position: fixed;
    padding: 4px;
    min-width: 250px;
    box-sizing: border-box;
    font-size: 13px;
    padding: 0px;
    padding-right: 5px;
    border-radius: 8px;

    & .rev-toast-body {
        & .rev-toast-item {
            animation-name: animation-right;
            animation-duration: 0.5s;
            cursor: pointer;
            padding: 12px;
            border-radius: 8px;
            box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.12);
            margin-bottom: 10px;

            div {
                margin: 0px;
                padding: 0px;

                &.tit {
                    font-size: 13px;
                    font-weight: bold;

                    &.msg {
                        padding-bottom: 10px;
                        margin-bottom: 10px;
                        border-bottom: solid 0.5px #dfdfdf;
                    }

                    & img {
                        &.toastIcon {
                            float: left;
                            vertical-align: middle;
                            margin-right: 5px;
                        }

                        &.toastCancelIcon {
                            float: right;
                            margin-top: 3px;
                        }
                    }
                }

                &.con {
                    display: block;
                    top: 50%;
                    font-size: 10px;
                    color: #000;
                    letter-spacing: -0.1px;
                    max-height: 70vh;
                    overflow-y: auto;

                    & span {
                        display: table-cell;
                        vertical-align: middle;
                    }
                }
            }

            &.default {
                border: solid 0.5px #000;
                background-color: #fff;
            }

            &.info {
                color: #ff6b00;
                border: solid 0.5px #ff6b00;
                background-color: #ffede1;
            }

            &.error {
                color: #ce2029;
                border: solid 0.5px #ce2029;
                background-color: #fff1f1;
            }

            &.warning {
                color: #ffcd00;
                border: solid 0.5px #ffcd00;
                background-color: #fff9e1;
            }

            &.success {
                color: #009739;
                border: solid 0.5px #009739;
                background-color: #eefff3;
            }
        }
    }
}

/* Horizontal Grid */
.horizontal-grid-root {
    display: block;
    flex-wrap: wrap;
    justify-content: flex-start;
    overflow: hidden;
    margin-top: 20px;
    height: 200px;

    & .grid-list {
        flex-wrap: nowrap;
        transform: translateZ(0);
        text-align: center;
        font-size: small;
    }

    & .validation-text-field {
        input {
            font-size: 13px;
            padding: 6px;
        }

        & .input:valid+fieldset {
            border-color: green;
        }

        & .input:invalid+fieldset {
            border-color: red;
        }
    }
}

/* 체크박스 */
.rev-checkBoxoptionGroup-root {
    label {
        display: inline-flex;
        margin-right: 17px;
        margin-bottom: 15px;
        cursor: pointer;

        input {
            width: 14px;
            height: 14px;
            border-radius: 2px;
            border: solid 1px #cccccc;
            appearance: none;
            outline: none;
            vertical-align: middle;
            margin: 0px 3px 0px 0px;
            cursor: pointer;

            &.check {
                background: transparent;
                background-image: $checkbox-on;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 16px 16px;
                border: none;
            }
        }
    }
}

/* datepicker one choice */
.rev-datepicker-onechoice-root {
    display: inline-flex;
    font-size: 13px;
    padding: 9px 12px;
    border-radius: 4px;
    border: solid 1px #dfdfdf;
    background-color: #ffffff;
    outline-color: #fff;
    border-radius: 4px;
    margin-left: 0px;
    cursor: pointer;

    img {
        cursor: pointer;
        vertical-align: middle;

        &.essential {
            display: none;
            width: auto;
            margin-top: 1px;
        }
    }

    .react-datepicker__input-container {
        display: inline-flex;
    }

    input {
        display: block;
        width: 100%;
        text-align: left;
        float: left;
        padding: 0px;
        border: 0px;
        caret-color: #fff;
        background: none;
        box-sizing: content-box;
        font-size: 13px;
        color: #000;
        height: 100%;
        letter-spacing: -0.13px;
        background-image: $dateCalendar;
        background-repeat: no-repeat;
        background-position: 95% 0%;
        background-size: 16px 16px;
        cursor: pointer;

        &:focus-within {
            caret-color: $primary;
            outline: 0;
        }

        &::placeholder {
            color: #999;
        }

        &:disabled {
            color: #000;
            -webkit-text-fill-color: #000;
            opacity: 1;
        }
    }
}

.rev-datepicker-onechoice-root,
.dateClass {
    .react-datepicker {
        border: 1px solid $primary;
    }

    .react-datepicker__header {
        background-color: $primary-horver-background-color;
        border-bottom: 1px solid $primary;
    }

    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
        border-bottom-color: $primary-horver-background-color;
    }

    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
        border-bottom-color: $primary;
    }

    .react-datepicker__navigation--previous {
        border-right-color: $primary;
    }

    .react-datepicker__navigation--previous:hover {
        border-right-color: $primary;
    }

    .react-datepicker__navigation--next {
        border-left-color: $primary;
    }

    .react-datepicker__navigation--next:hover {
        border-left-color: $primary;
    }

    .react-datepicker__day:hover,
    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover,
    .react-datepicker__year-text:hover {
        background-color: $primary-horver-background-color;
    }

    .react-datepicker__time-container {
        border-left: 1px solid $primary;
    }

    .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
        display: flex;

        input {
            width: auto;
        }
    }
}

.rev-list-root {
    &.disabled {
        ul {
            border: solid 0px #fff;
            position: relative;
            padding: 7px 0px 7px 0px;
            display: inline-block;
            width: 100%;
            margin: 6px 0px 10px 0px;
            border-radius: 4px;
            max-height: 100px !important;
            overflow-y: scroll;

            .rev-list-li {
                background-color: #f7f7f7;
                border-radius: 4px;
                margin: 3px 0px;

                &:first-child {
                    display: flex;
                }

                .rev-list-p {
                    margin: 0px 5px 0px 5px;
                    padding: 7px 0px 7px 3px;

                    &:hover {
                        background-color: #f7f7f7;
                        margin: 0px 5px 0px 5px;
                        padding: 7px 0px 7px 3px;
                        border-radius: 4px;
                    }

                    .rev-list-img {
                        display: none;
                    }
                }
            }
        }
    }

    ul {
        border: solid 1px #dfdfdf;
        position: relative;
        padding: 7px 0px 7px 0px;
        display: inline-block;
        width: 100%;
        margin: 6px 0px 10px 0px;
        border-radius: 4px;
        max-height: 100px !important;
        overflow-y: scroll;

        .rev-list-li {
            &:first-child {
                display: flex;
            }

            .rev-list-p {
                margin: 0px 5px 0px 5px;
                padding: 7px 0px 7px 3px;

                &:hover {
                    background-color: #fff8db;
                    margin: 0px 5px 0px 5px;
                    padding: 7px 0px 7px 3px;
                    border-radius: 4px;
                }

                .rev-list-img {
                    float: right;
                    margin: 1px 8px 0px 10px;
                    cursor: pointer;
                }
            }
        }
    }

    // Ip목록
    &.disabled {
        & input {
            background-color: #f7f7f7;
            border: 0px !important;
            border-radius: 4px;
            padding: 9.5px 12px;
            outline: none;
        }
    }

    & input {
        background-color: #fff;
        border: 1px solid #dfdfdf;
        border-radius: 4px;
        padding: 9.5px 12px;
        margin-top: 15px;
        outline: none;
    }
}

/*캘린더 버튼 컬러 및 다음/이전버튼 수정*/
.react-datepicker__tab-loop {
    .react-datepicker-popper {
        z-index: 2;
    }
}

.react-datepicker {

    .react-datepicker__navigation--next,
    .react-datepicker__navigation--previous {
        outline: none;
        margin-top: 6px;
    }

    .react-datepicker__month-container {
        font-family: $basic-font;

        .react-datepicker__header {
            //background-color: #ffcd00 !important;
            padding-top: 15px;
        }

        .react-datepicker__day-names {
            padding-top: 7px;
        }

        .react-datepicker__month {

            .react-datepicker__day--keyboard-selected,
            .react-datepicker__month-text--keyboard-selected,
            .react-datepicker__quarter-text--keyboard-selected,
            .react-datepicker__year-text--keyboard-selected {
                border-radius: 0.3rem;
                background-color: #2683F2 !important;
                color: #fff;
            }
        }
    }
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    border-radius: 0.3rem;
    background-color: #2683F2 !important; // date range
    color: #fff;
}

/*날짜검색 영역*/
.dateOption {
    margin-left: 5px;
}

.dateClass {
    margin-left: 5px;
    margin-right: 5px;
    display: flex;

    .datePicker {
        padding: 10px 12px;
        border: 1px solid #dfdfdf;
        border-radius: 5px;
        outline: none;
        height: 35px;
        background-image: $dateCalendar; // 달력안 이미지
        background-repeat: no-repeat;
        background-position: 95% 50%;
        background-size: 20px 20px;
        cursor: pointer;
        width: 100%;
        display: flex;
        font-family: $basic-font;
    }

    .react-datepicker-wrapper {
        width: 122px;
    }

    .dateFilterData {
        border: 1px solid #dfdfdf;
        border-radius: 4px;
        list-style: none;
        //display: none;
        padding: 0px;
        display: flex;
        margin: 0px 6px;
        height: 34px;
    }

    .dateInput {
        background: #fff !important;
        outline: none;
        cursor: pointer;
        padding: 10px 12px;
        border: 0px;
        border-right: 1px solid #dfdfdf;
        font-family: $basic-font;

        &:nth-child(1) {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        &:nth-child(3) {
            border-right: 0px;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }

        &:hover {
            background: #fff9e0 !important;
            cursor: pointer;
        }

        &.on {
            background: $primary !important;
            cursor: pointer;
            font-weight: 600;
        }
    }

    .dateSpan {
        margin: 11px 5px;
        display: inline-block;
    }

    .txt {
        font-size: 15px;
        color: #FFFFFF;
    }

    con {
        font-size: 15px !important;
    }

    .DateFilterData {
        padding: 10px 12px;
        background: #fff !important;
        cursor: pointer;
        border: 0px;
        border-radius: 4px;
        outline: none;
        font-family: $basic-font;

        &:hover {
            background: #fff9e0 !important;
            font-weight: bold;
            cursor: pointer;
        }

        &.on {
            background: #ffcd00 !important;
            cursor: pointer;
        }
    }
}

.rev-Btninput-root {
    display: inline-flex;
    font-size: 13px;
    padding: 9px 12px;
    border-radius: 4px;
    border: solid 1px #dfdfdf;
    background-color: #ffffff;
    outline-color: #fff;
    float: left;
    border-radius: 4px;
    margin-left: 0px;

    img {
        cursor: pointer;
        vertical-align: middle;

        &.cancel {
            visibility: hidden;
            float: left;
            display: block;
            width: auto;
        }

        &.searchActive {
            visibility: hidden;
            float: right;
            display: block;
        }

        &.search {
            display: inline;
            float: left;
            display: block;
            margin-right: 4px;
            width: auto;
            height: 100%;
        }
    }

    &.focus {
        border: solid 1.5px $primary;

        img {
            &.search {
                display: none;
            }

            &.searchActive {
                visibility: visible;
                width: auto;
                height: 100%;
                margin-top: -2px;
                width: auto;
                height: 18px;
                margin-left: 12px;
            }
        }

        &.x {
            img {
                &.cancel {
                    visibility: visible;
                }
            }
        }
    }

    input {
        display: block;
        width: 100%; //calc(100% - 21px);
        text-align: left;
        float: left;
        padding: 0px;
        border: 0px;
        caret-color: #fff;
        background: none;
        box-sizing: content-box;
        font-size: 13px;
        color: #000;
        height: fit-content;

        &:focus-within {
            caret-color: $primary;
            outline: 0;
        }

        &::placeholder {
            color: #999;
        }

        &:disabled {
            color: #999;
            -webkit-text-fill-color: #999 !important;
        }
    }
}

/*버튼이 있는 인풋*/
.rev-inputBtnDefault-root {
    transition: all 0.25s ease;
    display: flex;
    min-width: fit-content;
    //width: inherit;
    padding: 8px 10px 8px 12px;
    width: 100%;

    &.disabled {
        display: none;
        font-family: $basic-font;
    }

    &.valid {
        border: solid 0.5px #ff0000;
    }
}

.btn {
    cursor: pointer;
    width: 45px;
    height: 22px;
    padding: 3px;
    border: 1px solid #ccc;
    background: #fff;
    border-radius: 4px;
    margin: -3px 0px 0px 0px;
    font-family: $basic-font;
    font-weight: 600;
}

.rev-btnGroup-root {
    width: fit-content;
    display: inline-flex;

    ul {
        list-style: none;
        display: none;
        padding: 0px;
        display: flex;
        border-radius: 5px;
        border: solid 1px #dfdfdf;
        background-color: #ffffff;
        margin: 0px;

        li {
            cursor: pointer;
            text-align: center;
            min-width: 48px;
            float: left;
            padding: 10px 12px;
            border-right: solid 1px #dfdfdf;

            &:last-child {
                border-right: 0px;
            }

            &:hover {
                background-color: $primary-horver-background-color;
            }

            &.on {
                background-color: $primary;
                font-weight: bold;
            }
        }
    }
}

/*라디오 버튼*/
.rev-radioGroup-root {
    display: block;
    margin-top: 10px;
    margin-bottom: 5px;
    float: left;

    &.disabled {
        // 상세화면일때

        .radio-label input.on+.ico {
            // .radio-label input:checked + .ico {
            background: transparent;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            background-image: $radioSelectOff; // 체크된 라디오 버튼 이미지
        }

        .radio-label input.on+.ico+.txt {
            display: inline-block;
            padding-left: 20px;
            font-size: 13px;
            color: #FFFFFF;
            margin-right: 14px;
        }
    }

    ul {
        display: flex;

        li {
            display: contents;

            .radio-label {
                //overflow: hidden;
                display: inline-block;
                position: relative;
                height: 26px;
                box-sizing: border-box;
                cursor: pointer;
            }

            .radio-label input {
                overflow: hidden;
                display: none;
                width: 0px;
                height: 0px;
                border: 0 none;
                font-size: 0;
                line-height: 0;
                clip: rect(0 0 0 0);
                opacity: 0;
            }

            .radio-label .txt {
                //display: unset;
                // 운영자관리 추가
                width: max-content;
                padding-left: 20px;
                font-size: 13px;
                color: #cecece;
                margin-right: 14px;
            }

            .radio-label .ico {
                position: absolute;
                left: 0px;
                top: 0;
                width: 16px;
                height: 16px;
                background: transparent;
                background-image: $radioOff;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
            }

            /* 체크됐을때, 이미지변경 */
            // .radio-label input:checked + .ico,
            .radio-label input.on+.ico {
                background: transparent;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                background-image: $radioOn;
            }

            .radio-label input.on+.ico+.txt {
                display: inline-block;
                padding-left: 20px;
                font-size: 13px;
                color: #FFFFFF;
                margin-right: 14px;
            }
        }
    }
}

/* 모바일 가로, 모바일 세로 (해상도 ~ 1066px)*/
@media all and (max-width: 1066px) {
    .dateOption {
        width: 100%;
    }

    .datePicker {
        width: 100%;
        display: flex;
    }
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width: 767px) {
    .operWork {
        margin-left: 0px;
        margin-top: 10px;
    }

    .rev-toast-container-root {
        top: 10px;
    }

    .dateOption {
        display: inline-block !important;
        width: 100%;
        margin: 10px 0px;
    }

    .dateClass {
        width: 100%;
        margin: 9px 0px -2px 0px;

        .datePicker {
            width: 100%;
            display: flex;
        }
    }

    .dateSpan {
        margin: 20px 5px 0px 5px !important;
        display: inline-block;
    }

    .dateClass {
        .react-datepicker-wrapper {
            display: inline-block;
            padding: 0;
            border: 0;
            width: 50%;
            margin-top: 10px;
        }
    }

    .rev-radioGroup-root {
        ul {
            display: initial;

            li {
                .radio-label input.on+.ico+.txt {
                    display: inline-block;
                    padding-left: 20px;
                    font-size: 13px;
                    color: #FFFFFF;
                    margin-right: 14px;
                }
            }
        }
    }

    /* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
    @media all and (max-width: 767px) {
        .rev-toast-container-root {
            top: 10px;
        }

        /* 페이징 */
        .rev-pagination-root {
            li {
                &.pNumber {
                    height: 32px;

                    ul {
                        padding-left: 0px;

                        li {
                            height: 32px;
                            width: 32px;

                            span {
                                margin-top: 3px;
                                display: block;
                            }
                        }
                    }
                }

                &.pPrevious {
                    width: 32px;
                    height: 32px;
                }

                &.pNext {
                    width: 32px;
                    height: 32px;
                }
            }
        }
    }
}