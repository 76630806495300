@charset "utf-8";
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);

* {
    font-family: 'Spoqa Han Sans Neo', 'sans-serif' !important;
}

input:focus {
    outline-color: #2683f2;
}

body {
    padding: 0;
    margin: 0;
    position: relative;
}

div,
section,
header,
article {
    box-sizing: border-box;
}
/** 추가내용: 웹 브라우저 기본 사이즈 정의 */
html,
body {
    width: 100%;
    height: 100%;
}

/** 아래 코드는 reset.css의 원본 내용 */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    letter-spacing: -0.5px;
}
@media screen and (max-width: 960px) {
    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
    }
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
    font-size: 62.5%;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

a {
    text-decoration: none;
}

/*본문과의 구분을 위한 여백*/
h1 {
    letter-spacing: 3px;
}
h2 {
    word-spacing: 2px;
}
/* --여기까지--*/
body {
}

.t {
    border: 1px solid #f00;
}

select {
    font-size: 14px;
    line-height: 20px;
    padding: 5px 0;
}
hr {
    border: none;
    border-bottom: 1px solid #e5e5e5;
}

.MuiTable-root {
    border-collapse: separate !important;
}

/************   margin     *********************/

.noMG {
    margin: 0 !important;
}
.mt100 {
    margin-top: 100px !important;
}
.mt95 {
    margin-top: 95px !important;
}
.mt90 {
    margin-top: 90px !important;
}
.mt85 {
    margin-top: 85px !important;
}
.mt80 {
    margin-top: 80px !important;
}
.mt75 {
    margin-top: 75px !important;
}
.mt70 {
    margin-top: 70px !important;
}
.mt65 {
    margin-top: 65px !important;
}
.mt60 {
    margin-top: 60px !important;
}
.mt55 {
    margin-top: 55px !important;
}
.mt50 {
    margin-top: 50px !important;
}
.mt45 {
    margin-top: 45px !important;
}
.mt40 {
    margin-top: 40px !important;
}
.mt35 {
    margin-top: 35px !important;
}
.mt30 {
    margin-top: 30px !important;
}
.mt25 {
    margin-top: 25px !important;
}
.mt20 {
    margin-top: 20px !important;
}
.mt15 {
    margin-top: 15px !important;
}
.mt10 {
    margin-top: 10px !important;
}
.mt5 {
    margin-top: 5px !important;
}
.mt1 {
    margin-top: 1px !important;
}
.mt0 {
    margin-top: 0px !important;
}

.mr100 {
    margin-right: 100px !important;
}
.mr95 {
    margin-right: 95px !important;
}
.mr90 {
    margin-right: 90px !important;
}
.mr85 {
    margin-right: 85px !important;
}
.mr80 {
    margin-right: 80px !important;
}
.mr75 {
    margin-right: 75px !important;
}
.mr70 {
    margin-right: 70px !important;
}
.mr65 {
    margin-right: 65px !important;
}
.mr60 {
    margin-right: 60px !important;
}
.mr55 {
    margin-right: 55px !important;
}
.mr50 {
    margin-right: 50px !important;
}
.mr45 {
    margin-right: 45px !important;
}
.mr40 {
    margin-right: 40px !important;
}
.mr35 {
    margin-right: 35px !important;
}
.mr30 {
    margin-right: 30px !important;
}
.mr25 {
    margin-right: 25px !important;
}
.mr20 {
    margin-right: 20px !important;
}
.mr15 {
    margin-right: 15px !important;
}
.mr10 {
    margin-right: 10px !important;
}
.mr5 {
    margin-right: 5px !important;
}

.mb100 {
    margin-bottom: 100px !important;
}
.mb95 {
    margin-bottom: 95px !important;
}
.mb90 {
    margin-bottom: 90px !important;
}
.mb85 {
    margin-bottom: 85px !important;
}
.mb80 {
    margin-bottom: 80px !important;
}
.mb75 {
    margin-bottom: 75px !important;
}
.mb70 {
    margin-bottom: 70px !important;
}
.mb65 {
    margin-bottom: 65px !important;
}
.mb60 {
    margin-bottom: 60px !important;
}
.mb55 {
    margin-bottom: 55px !important;
}
.mb50 {
    margin-bottom: 50px !important;
}
.mb45 {
    margin-bottom: 45px !important;
}
.mb40 {
    margin-bottom: 40px !important;
}
.mb35 {
    margin-bottom: 35px !important;
}
.mb30 {
    margin-bottom: 30px !important;
}
.mb25 {
    margin-bottom: 25px !important;
}
.mb20 {
    margin-bottom: 20px !important;
}
.mb15 {
    margin-bottom: 15px !important;
}
.mb10 {
    margin-bottom: 10px !important;
}
.mb5 {
    margin-bottom: 5px !important;
}

.ml100 {
    margin-left: 100px !important;
}
.ml95 {
    margin-left: 95px !important;
}
.ml90 {
    margin-left: 90px !important;
}
.ml85 {
    margin-left: 85px !important;
}
.ml80 {
    margin-left: 80px !important;
}
.ml75 {
    margin-left: 75px !important;
}
.ml70 {
    margin-left: 70px !important;
}
.ml65 {
    margin-left: 65px !important;
}
.ml60 {
    margin-left: 60px !important;
}
.ml55 {
    margin-left: 55px !important;
}
.ml50 {
    margin-left: 50px !important;
}
.ml45 {
    margin-left: 45px !important;
}
.ml40 {
    margin-left: 40px !important;
}
.ml35 {
    margin-left: 35px !important;
}
.ml30 {
    margin-left: 30px !important;
}
.ml25 {
    margin-left: 25px !important;
}
.ml20 {
    margin-left: 20px !important;
}
.ml15 {
    margin-left: 15px !important;
}
.ml10 {
    margin-left: 10px !important;
}
.ml5 {
    margin-left: 5px !important;
}

/************   padding     *********************/

.pt100 {
    padding-top: 100px !important;
}
.pt95 {
    padding-top: 95px !important;
}
.pt90 {
    padding-top: 90px !important;
}
.pt85 {
    padding-top: 85px !important;
}
.pt80 {
    padding-top: 80px !important;
}
.pt75 {
    padding-top: 75px !important;
}
.pt70 {
    padding-top: 70px !important;
}
.pt65 {
    padding-top: 65px !important;
}
.pt60 {
    padding-top: 60px !important;
}
.pt55 {
    padding-top: 55px !important;
}
.pt50 {
    padding-top: 50px !important;
}
.pt45 {
    padding-top: 45px !important;
}
.pt40 {
    padding-top: 40px !important;
}
.pt35 {
    padding-top: 35px !important;
}
.pt30 {
    padding-top: 30px !important;
}
.pt25 {
    padding-top: 25px !important;
}
.pt20 {
    padding-top: 20px !important;
}
.pt15 {
    padding-top: 15px !important;
}
.pt10 {
    padding-top: 10px !important;
}
.pt5 {
    padding-top: 5px !important;
}
.pt0 {
    padding-top: 0px !important;
}

.pr100 {
    padding-right: 100px !important;
}
.pr95 {
    padding-right: 95px !important;
}
.pr90 {
    padding-right: 90px !important;
}
.pr85 {
    padding-right: 85px !important;
}
.pr80 {
    padding-right: 80px !important;
}
.pr75 {
    padding-right: 75px !important;
}
.pr70 {
    padding-right: 70px !important;
}
.pr65 {
    padding-right: 65px !important;
}
.pr60 {
    padding-right: 60px !important;
}
.pr55 {
    padding-right: 55px !important;
}
.pr50 {
    padding-right: 50px !important;
}
.pr45 {
    padding-right: 45px !important;
}
.pr40 {
    padding-right: 40px !important;
}
.pr35 {
    padding-right: 35px !important;
}
.pr30 {
    padding-right: 30px !important;
}
.pr25 {
    padding-right: 25px !important;
}
.pr20 {
    padding-right: 20px !important;
}
.pr15 {
    padding-right: 15px !important;
}
.pr10 {
    padding-right: 10px !important;
}
.pr5 {
    padding-right: 5px !important;
}

.pb100 {
    padding-bottom: 100px !important;
}
.pb95 {
    padding-bottom: 95px !important;
}
.pb90 {
    padding-bottom: 90px !important;
}
.pb85 {
    padding-bottom: 85px !important;
}
.pb80 {
    padding-bottom: 80px !important;
}
.pb75 {
    padding-bottom: 75px !important;
}
.pb70 {
    padding-bottom: 70px !important;
}
.pb65 {
    padding-bottom: 65px !important;
}
.pb60 {
    padding-bottom: 60px !important;
}
.pb55 {
    padding-bottom: 55px !important;
}
.pb50 {
    padding-bottom: 50px !important;
}
.pb45 {
    padding-bottom: 45px !important;
}
.pb40 {
    padding-bottom: 40px !important;
}
.pb35 {
    padding-bottom: 35px !important;
}
.pb30 {
    padding-bottom: 30px !important;
}
.pb25 {
    padding-bottom: 25px !important;
}
.pb20 {
    padding-bottom: 20px !important;
}
.pb15 {
    padding-bottom: 15px !important;
}
.pb10 {
    padding-bottom: 10px !important;
}
.pb5 {
    padding-bottom: 5px !important;
}

.pl100 {
    padding-left: 100px !important;
}
.pl95 {
    padding-left: 95px !important;
}
.pl90 {
    padding-left: 90px !important;
}
.pl85 {
    padding-left: 85px !important;
}
.pl80 {
    padding-left: 80px !important;
}
.pl75 {
    padding-left: 75px !important;
}
.pl70 {
    padding-left: 70px !important;
}
.pl65 {
    padding-left: 65px !important;
}
.pl60 {
    padding-left: 60px !important;
}
.pl55 {
    padding-left: 55px !important;
}
.pl50 {
    padding-left: 50px !important;
}
.pl45 {
    padding-left: 45px !important;
}
.pl40 {
    padding-left: 40px !important;
}
.pl35 {
    padding-left: 35px !important;
}
.pl30 {
    padding-left: 30px !important;
}
.pl25 {
    padding-left: 25px !important;
}
.pl20 {
    padding-left: 20px !important;
}
.pl15 {
    padding-left: 15px !important;
}
.pl10 {
    padding-left: 10px !important;
}
.pl5 {
    padding-left: 5px !important;
}
